import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n);

const messages = {
  "en": {
    title: 'Photo Album',
    login: {
      title: 'Login',
      username: 'Username',
      password: 'Password',
      login_with_teamnote: 'Login with TeamNoteOAuth',
      login_failed_message: 'Login failed. Please try again.'
    },
    logout: {
      title: 'Log out'
    },
    album: {
      title: 'Albums',
      search: 'Search',
      photo_count: '{count} Photos',
      visible_until_day: '{day} days',
      visible_until: 'Visible until {d}',
      view_no: '{view_no} View(s)',
      album_creation_days: '{d} Day(s) Since Creation',
    },
    album_request: {
      title: 'Album Create Requests',
      create_title: 'New Album Create Request',
      created_at: 'Requested At',
      created_by: 'Requested By',
      mobile: 'Tel. No.',
      email: 'Email Address',
      email_hint: '(You will be informed of the progress through email.)',
      album_title: 'Album Title',
      purpose: 'Purpose',
      admins: 'Other Owners',
      admins_hint: '(with the right to add/delete photos/sub-albums)',
      approved: 'Approval Result',
      approved_true: 'Approved',
      approved_false: 'Rejected',
      approved_pending: 'Pending Approval',
      approved_by: 'Approval Handled By',
      approved_at: 'Approval Handled At',
      submit: 'Submit',
      submit_success_msg: 'Request submitted successfully. You will be informed of the progress through email.',

      delete_confirm_msg: 'Cancel Album Create Request?',
      delete_success_msg: 'Request cancelled successfully.',
    },

    error_messages: {
      title_already_in_use: 'Album with the same title already exists',
    },
  },
  "zh": {},
};

const i18n = new VueI18n({
  locale: 'en',
  messages,
});

export default i18n;
