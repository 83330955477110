import Vue from 'vue'
import VueRouter from 'vue-router'
import AlbumListView from "@/views/AlbumListView";
import AlbumView from "@/views/AlbumView.vue";
import AlbumRequestCreateView from "@/views/AlbumRequestCreateView.vue";
import AlbumRequestListView from "@/views/AlbumRequestListView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/:autoLogin?',
    name: 'album-list',
    component: AlbumListView,
  },
  {
    path: '/requests',
    name: 'album-request',
    component: AlbumRequestListView,
  },
  {
    path: '/create_request',
    name: 'album-request-create',
    component: AlbumRequestCreateView,
  },
  {
    path: '/:albumId',
    name: 'photo-list',
    component: AlbumView,
  },
]

const router = new VueRouter({
  routes
})

export default router
