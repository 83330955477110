<template>
  <div class="album-item-list">
    <div class="album-item-list-parent" v-if="currentParent">
      <v-btn text x-small color="primary"
             @click="onBackClicked">
        &lt;&lt; Current: {{ currentParent.caption }}
      </v-btn>
    </div>
    <div class="album-item-list-content" ref="albumItemList" @scroll="onAlbumListScroll">
      <v-col cols="6" sm="4" md="3" lg="2" class="album-item-cell" v-for="(item, index) in filteredItems" :key="index">
        <div class="album-item-card">
          <v-img v-if="item.type !== 'FOLDER'"
                 :src="item.thumbnail" width="100%" aspect-ratio="1" class="album-item-thumbnail"
                 @click="onItemClick(item)">
            <v-icon v-if="item.type === 'VIDEO'"
                    color="white"
                    class="album-item-video-play-icon">mdi-play-circle-outline
            </v-icon>
          </v-img>
          <!-- <div v-if="item.type === 'FOLDER'"
               class="album-item-folder-icon-wrapper"
               @click="onFolderClick(item)">
            <v-icon class="album-item-folder-icon"
                    width="100%">
              mdi-folder-outline
            </v-icon>
          </div> -->
          <template v-if="item.type === 'FOLDER'">
            <div class="album-item-folder-icon-wrapper"
               @click="onFolderClick(item)"
               v-if="!item.cover_photo">
              <v-icon class="album-item-folder-icon"
                      width="100%">
                mdi-folder-outline
              </v-icon>
            </div>
            <v-img v-else
                  :src="item.cover_photo.thumbnail" width="100%" aspect-ratio="1" class="album-item-thumbnail"
                  @click="onFolderClick(item)">
            </v-img>
          </template>
         
          <div class="album-item-footer">
            <div v-if="item.type === 'FOLDER'"
                 class="album-item-folder-caption">
              {{ item.caption }}
            </div>

            <div v-if="item.type !== 'FOLDER'"
                 class="album-item-download-btn">
              <v-btn icon color="primary" @click="downloadPhoto(item)">
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>
            </div>
            <div v-if="item.type !== 'FOLDER'"
                 class="album-item-view-count">
              <v-icon color="secondary" small class="mr-2">mdi-eye</v-icon>
              {{ item.view_no }}
            </div>
          </div>
        </div>
      </v-col>
      <!-- photos loading placeholder -->
      <template v-if="isLoading">
        <v-col cols="6" sm="4" md="3" lg="2" class="album-item-cell" v-for="(i, index) in 6">
          <div class="album-item-card">
            <div class="album-item-folder-icon-wrapper image-loading"></div>
            <div class="album-item-footer">
              <div class="album-item-download-btn">
                <v-btn icon color="primary">
                  <v-icon>mdi-tray-arrow-down</v-icon>
                </v-btn>
              </div>
              <div class="album-item-view-count">
                <v-icon color="secondary" small class="mr-2">mdi-eye</v-icon><span>0</span>
              </div>
            </div>
          </div>
        </v-col>
      </template>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import AlbumItemViewer from "@/components/AlbumItemViewer.vue";
import _ from "lodash";

export default {
  name: 'AlbumItemList',
  props: {
    albumId: String,
  },
  setup(props, {root, emit}) {
    const pageNo = ref(1);
    const pageSize = ref(24);
    const sortBy = ref([]);
    const totalNo = ref(null);
    const totalPageNo = computed(() => {
      if (totalNo.value) {
        return Math.ceil(totalNo.value / pageSize.value);
      }
      return 1;
    })

    const items = ref([]);

    const lastParent = ref(null)
    const currentParent = ref(null);
    watch(() => currentParent.value, (newValue, oldValue) => {
      if (!_.isEqual(newValue, oldValue)) {
        getFolderAlbumPhotos();
      }

      emit('update-is-root-folder', newValue);
    });

    const getFolderAlbumPhotos = () => {
      resetPaginationConfig();
      getItems(true);
    }

    const resetPaginationConfig = () => {
      pageNo.value = 1;
      allItemLoaded.value = false;
      isLoading.value = false;
      items.value = [];
    }

    const filteredItems = computed(() => {
      let filtered = [];

      if (currentParent.value) {
        filtered = _.filter(items.value, {parent: currentParent.value.id});
      } else {
        filtered = _.filter(items.value, {parent: null});
      }

      let sortedFolders = _.sortBy(_.filter(filtered, {type: 'FOLDER'}), 'order_no');
      let sortedphotos = _.sortBy(_.without(filtered, ...sortedFolders), 'order_no');

      filtered = _.concat(sortedFolders, sortedphotos);

      return filtered
    })

    watch(() => filteredItems.value, (newValue, oldValue) => {
      if (newValue.length <= pageSize.value) {
        getItems(true)
      }
    });

    const albumItemList = ref();
    const isLoading = ref(false);
    const allItemLoaded = ref(false);
    const SCROLL_BOTTOM_THRESHOLD = 300;
    const onAlbumListScroll = (event) => {
      throttleLoadingItems();
    }

    const computeScrollPos = () => {
      if (!albumItemList.value) {
        return;
      }

      let element = albumItemList.value;
      let scrollPos = element.scrollHeight - element.scrollTop;
      let scrollToBottomThreshold = scrollPos <= element.clientHeight + scrollPos / 2;
      // console.log('scrollPos', scrollPos);
      if (scrollToBottomThreshold) {
        getItems(true)
      }
    }

    const throttleLoadingItems = _.throttle(computeScrollPos, 100);
    
    const getItems = async function (not_blocking = false) {
      if (allItemLoaded.value) {
        return
      }

      if (isLoading.value) {
        return
      }

      isLoading.value = true;
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'album/item/',
        params: {
          filters: {
            'album.id': root.$route.params.albumId,
            'parent.id' : currentParent.value?.id || false  
          },
          sort_by: ["type", "order_no"],
          page_no: pageNo.value,
          page_size: pageSize.value
        },
        opt: {not_blocking},
      });

      if (response.body.success) {
        items.value = items.value.concat(response.body.items);
        totalNo.value = response.body.count;

        onUpdateTotalCount();

        if (response.body.items.length < pageSize.value) {
          allItemLoaded.value = true;
        } else {
          pageNo.value += 1;
        }

        isLoading.value = false;
      }
    }

    const onUpdateTotalCount = function () {
      const groupByType = _.groupBy(items.value, 'type')

      const countObj = { 
        image_count: groupByType['IMAGE'] ? _.size(groupByType['IMAGE']) : 0,
        // video_count: groupByType['VIDEO'] ? _.size(groupByType['VIDEO']) : 0
        folder_count: groupByType['FOLDER'] ? _.size(groupByType['FOLDER']) : 0
      }

      emit('update-total-count', countObj);
    }

    const onBackClicked = function () {
      currentParent.value = lastParent.value;
      if (currentParent.value?.parent) {
        lastParent.value = _.find(items.value, {id: currentParent.value.parent});
      } else {
        lastParent.value = null;
      }
    }
    const onFolderClick = function (folder) {
      lastParent.value = currentParent.value;
      currentParent.value = folder;
    }
    const onItemClick = function (item) {
      root.$dialog.showAndWait(AlbumItemViewer, {
        viewingItemId: item.id,
        albumId: root.$route.params.albumId,
        parentId: currentParent.value ? currentParent.value.id : null,
        width: '100vw',
        showClose: false,
      })
    }
    const downloadPhoto = function (item) {
      root.$downloadImage(item);
    }

    onMounted(() => {
      getItems(true);
    })

    return {
      isLoading,
      onAlbumListScroll,
      albumItemList,
      pageNo,
      totalPageNo,
      pageSize,
      sortBy,
      totalNo,
      items,
      lastParent,
      currentParent,
      filteredItems,

      onBackClicked,
      onFolderClick,
      onItemClick,
      downloadPhoto,
    }
  },
}
</script>

<style lang="less">
.album-item-list {
  flex: 1 1 0;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;

  .album-item-list-content {
    flex: 1 1 0;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    align-content: flex-start;

    .album-item-cell {
      padding: 4px;

      .album-item-card {
        width: 100%;

        .album-item-thumbnail {
          cursor: zoom-in;
          text-align: center;
          align-items: center;

          .album-item-video-play-icon {
            text-shadow: 2px 2px 4px black;
            font-size: 60px;
          }
        }

        .album-item-folder-icon-wrapper {
          width: 100%;
          padding-top: 100%;
          position: relative;

          .album-item-folder-icon {
            position: absolute;
            top: calc(50% - 48px);
            left: calc(50% - 48px);
            font-size: 96px;
          }

          &.image-loading {
            position: relative;
            background: rgba(0, 0, 0, .12);
            overflow: hidden;

            &::after {
              background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .3), hsla(0, 0%, 100%, 0));
            
              content: "";
              animation: image-loading 1.5s infinite;
              height: 100%;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              transform: translateX(-100%);
              z-index: 1;
            }
          }
        }

        .album-item-footer {
          height: 42px;
          background: white;
          display: flex;
          padding: 4px;

          .album-item-folder-caption {
            color: var(--v-primary-base);
            padding: 4px;
          }

          .album-item-download-btn {
            flex: 0 0 auto;
          }

          .album-item-view-count {
            flex: 1 1 auto;
            font-weight: bold;
            color: var(--v-secondary-base);
            font-size: 75%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }
  }

  @keyframes image-loading {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
