import {ACTION_TYPES} from "@/store/types";

export default {
    state: {
        displayingPhotos: [],
    },
    mutations: {
        showPhotos(state, photos) {
            state.displayingPhotos = photos;
        },
        stopShowingPhotos(state) {
            state.displayingPhotos = [];
        },
    },
    actions: {
        [ACTION_TYPES.SHOW_PHOTOS]: {
            root: true,
            handler({commit}, photos) {
                commit('showPhotos', photos);
            },
        },
        [ACTION_TYPES.HIDE_PHOTOS]: {
            root: true,
            handler({commit}) {
                commit('stopShowingPhotos');
            },
        },
    },
    getters: {
        displayingPhotos(state) {
            return state.displayingPhotos;
        },
    },
};
