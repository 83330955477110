import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","flat":"","color":"white","floating":"","id":"appBar"}},[_c(VImg,{attrs:{"src":require("@/assets/img.png"),"max-height":"48px","max-width":"100px","contain":""}}),_c('span',{class:['app-bar-title', {'font-weight-bold text-subtitle-2': _vm.screen_w < 500} ]},[_vm._v(_vm._s(_vm.$t('title')))]),_c(VSpacer),(_vm.account)?_c('div',{class:['font-weight-bold', {'text-subtitle-2': _vm.screen_w < 500} ]},[_vm._v(" "+_vm._s(_vm.account.name)+" ")]):_vm._e(),(_vm.isLoggedIn)?_c(VBtn,{staticClass:"ml-4",attrs:{"color":"primary","small":""},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t('logout.title'))+" "),_c(VIcon,{attrs:{"size":"20","right":""}},[_vm._v("mdi-logout-variant")])],1):_vm._e()],1),_c(VContent,[_c(VContainer,{staticClass:"fill-height content-wrapper",attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('PhotoViewer'),_c(VSnackbar,{attrs:{"top":_vm.isSnackBarTop,"transition":"scroll-y-transition","timeout":3000},model:{value:(_vm.snackBarShown),callback:function ($$v) {_vm.snackBarShown=$$v},expression:"snackBarShown"}},[_vm._v(" "+_vm._s(_vm.snackBarContent)+" ")]),_c(VOverlay,{attrs:{"value":_vm.isLoading,"z-index":"9999"}},[_c('BounceLoader',{attrs:{"color":"#ba1d21"}})],1),_c('LoginModal',{attrs:{"shown":_vm.loginDialogShown}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }