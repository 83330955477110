<template>
  <v-app>
    <v-app-bar app
               flat
               color="white"
               floating
               id="appBar">
      <v-img src="@/assets/img.png"
             max-height="48px"
             max-width="100px"
             contain></v-img>
      <span :class="['app-bar-title', {'font-weight-bold text-subtitle-2': screen_w < 500} ]">{{ $t('title') }}</span>
      <v-spacer></v-spacer>
      <div v-if="account"
           :class="['font-weight-bold', {'text-subtitle-2': screen_w < 500} ]">
        {{ account.name }}
      </div>
      <v-btn color="primary"
             class="ml-4"
             small
             v-if="isLoggedIn"
             @click="logout">
        {{ $t('logout.title') }}
        <v-icon size="20" right>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <v-container fluid class="fill-height content-wrapper">
        <router-view></router-view>
      </v-container>
    </v-content>

    <PhotoViewer></PhotoViewer>

    <v-snackbar v-model="snackBarShown"
                :top="isSnackBarTop"
                transition="scroll-y-transition"
                :timeout="3000">
      {{ snackBarContent }}
    </v-snackbar>

    <v-overlay :value="isLoading"
               z-index="9999">
      <BounceLoader color="#ba1d21"></BounceLoader>
    </v-overlay>

    <LoginModal :shown="loginDialogShown"></LoginModal>
  </v-app>
</template>

<script>

import {computed, onMounted, ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import PhotoViewer from "@/components/PhotoViewer";
import LoginModal from "@/components/LoginModal.vue";

export default {
  name: 'App',
  components: {LoginModal, PhotoViewer, BounceLoader},
  setup(props, {root}) {
    const drawerShown = ref(false);

    // Loading Spinner
    const isLoading = computed(() => {
      return root.$store.getters.requestCount !== 0;
    });

    const loginDialogShown = ref(false);
    const account = computed(() => {
      return root.$store.getters.account;
    });
    watch(() => account.value, async (newValue, oldValue) => {
      if (oldValue && !newValue) {
        console.log('LOGGED OUT');
        loginDialogShown.value = true;
      }

      if (newValue && !oldValue) {
        console.log('LOGGED IN');
        loginDialogShown.value = false;
        root.$router.replace({name: 'album-list'})
      }
    });

    const isLoggedIn = computed(() => {
      return root.$store.getters.isLoggedIn
    })

    const logout = async function () {
      await root.$store.dispatch(ACTION_TYPES.LOGOUT)
    }

    // Snack Bar
    const snackBarShown = ref(false);
    const snackBarContent = computed(() => root.$store.getters.snackBarContent);
    const isSnackBarTop = computed(() => root.$store.getters.isSnackBarTop);

    watch(() => root.$store.getters.snackBarContent, (content) => {
      if (content) {
        snackBarShown.value = true;
      }
    }, {immediate: true});

    watch(() => snackBarShown.value, (shown, oldShown) => {
      if (oldShown && !shown) {
        // root.$store.dispatch(ACTION_TYPES.HIDE_SNACKBAR);

        // clear snackBar content after the animation is ended
        let clearSnackBarContent = setTimeout(() => {
          root.$store.dispatch(ACTION_TYPES.HIDE_SNACKBAR);

          clearSnackBarContent && clearTimeout(clearSnackBarContent);
          clearSnackBarContent = null;
        }, 200);
      }
    }, {immediate: true});

    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);

      // Check if id_token is present
      const urlParams = new URLSearchParams(window.location.search);
      const id_token = urlParams.get('id_token');

      if (id_token) {
        await root.$store.dispatch(ACTION_TYPES.AUTHENTICATE_TEAMNOTE_JWT, {jwt: id_token});
        history.replaceState(null, '', window.location.pathname + window.location.hash)
      }

      try {
        await root.$store.dispatch(ACTION_TYPES.GET_ME);
      } catch (e) {
        loginDialogShown.value = true;
        
        if (root.$isTeamNote()) {
          root.$loginTeamNote('/');
        } else {
          // loginDialogShown.value = true;
          if (root.$route.params.autoLogin === 'sso') {
            root.$loginTeamNote('/');
          }
        }
      }
    });

    return {
      drawerShown,

      isLoading,

      loginDialogShown,
      account,

      isLoggedIn,
      logout,

      snackBarShown,
      snackBarContent,
      isSnackBarTop,
      screen_w: screen.width
    }
  },
};
</script>

<style lang="less">
.v-application--wrap {
  height: 100vh !important;
}

#appBar {
  .v-toolbar__content {
    width: 100%;
  }
}

.v-main {
  height: 100% !important;
  overflow: auto;
}

.app-bar-title {
  font-weight: bold;
  font-size: 150%;
}

.content-wrapper {
  background-color: #F4F5F9;
}
</style>
