import Vue from 'vue'
import Vuex from 'vuex'
import {accountModule} from "./modules/account";
import {snackBarModule} from "./snackBar";
import {apiModule} from "./modules/api";
import loadingSpinner from "./modules/loadingSpinner";
import photoViewer from "@/store/modules/photoViewer";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        account: accountModule,
        snackBar: snackBarModule,
        api: apiModule,
        loadingSpinner: loadingSpinner,
        photoViewer: photoViewer,
    },
})
