import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.album)?_c(VContainer,{staticClass:"album-view pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"album-breadcrumb"},[_c(VBtn,{attrs:{"text":"","x-small":"","color":"primary","exact":"","to":{ name: 'album-list' }}},[_vm._v(" << "+_vm._s(_vm.$t('album.title'))+" ")])],1),_c('div',{staticClass:"album-title"},[_vm._v(" "+_vm._s(_vm.album.title)+" "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"album-download-btn",attrs:{"icon":""},on:{"click":_vm.downloadAlbum}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-folder-zip")])],1)]}}],null,false,1534668640)},[_c('span',[_vm._v("Download Album")])])],1),(_vm.albumOwners)?_c('div',{staticClass:"album-owners"},[_vm._v("Owner(s): "+_vm._s(_vm.albumOwners)+" ")]):_vm._e(),_c('div',{staticClass:"album-create-date"},[_vm._v(" Create Date: "+_vm._s(_vm.$parseDate(_vm.album.created_at, 'dd/MM/yyyy'))+" ")]),_c('div',{staticClass:"album-create-date"},[_vm._v(" Last Update: "+_vm._s(_vm.$parseDate(_vm.album.updated_at, 'dd/MM/yyyy'))+" ")]),(_vm.isInRootFolder)?[_c('div',{staticClass:"album-create-date"},[_vm._v(" Total Image Count: "+_vm._s(_vm.album.media_count)+" ")]),_c('div',{staticClass:"album-create-date"},[_vm._v(" Total Sub-album Count: "+_vm._s(_vm.album.folder_count)+" ")])]:_vm._e(),_c('div',{staticClass:"album-divider my-4"}),_c('AlbumItemList',{attrs:{"album-id":_vm.album.id},on:{"update-total-count":_vm.updateTotalCount,"update-is-root-folder":_vm.checkIfInRootFolder}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }