import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"album-request-list"},[_c('div',{staticClass:"album-breadcrumb"},[_c(VBtn,{attrs:{"text":"","x-small":"","color":"primary","exact":"","to":{ name: 'album-list' }}},[_vm._v(" << "+_vm._s(_vm.$t('album.title'))+" ")])],1),_c(VRow,{staticClass:"flex-grow-0 mt-4"},[_c('h3',[_vm._v(_vm._s(_vm.$t('album_request.title')))]),_c(VSpacer),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"primary","small":"","to":{name: 'album-request-create'}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" New ")],1)],1),_c(VRow,{staticClass:"flex-grow-1"},[_c(VCol,[_c('AlbumRequestList',{attrs:{"full-height":"","refresh-trigger":_vm.refreshTrigger},on:{"update:refreshTrigger":function($event){_vm.refreshTrigger=$event},"update:refresh-trigger":function($event){_vm.refreshTrigger=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }