<template>
  <RemotePaginatedList :headers="headers"
                       multi-sort
                       data-url="request/"
                       data-key="requests"
                       :refresh-trigger.sync="innerRefreshTrigger"
                       v-bind="$attrs"
                       class="album-request-list">
    <template #item.created_at="{value}">
      {{ value ? $parseDate(value) : '' }}
    </template>
    <template #item.purpose="{value}">
      <v-tooltip bottom>
        <template #activator="{on, attrs}">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon color="primary">mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>{{ value }}</span>
      </v-tooltip>
    </template>
    <template #item.admins="{value}">
      <v-tooltip bottom>
        <template #activator="{on, attrs}">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon color="primary">mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span v-html="getAdmins(value)"></span>
      </v-tooltip>
    </template>
    <template #item.approved="{value}">
      {{ getApproved(value) }}
    </template>
    <template #item.approved_at="{value}">
      {{ value ? $parseDate(value) : '' }}
    </template>
    <!-- <template v-slot:item.actions="{item}">
      <div class="d-flex" v-if="item.approved === null">
        <v-btn icon
               color="primary"
               @click="deleteItem(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </template> -->
  </RemotePaginatedList>
</template>

<script>
import {onMounted, ref, watch} from '@vue/composition-api'
import RemotePaginatedList from "./RemotePaginatedList";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'AlbumRequestList',
  components: {RemotePaginatedList},
  props: {
    value: Array,
    refreshTrigger: Boolean,
  },
  setup(props, {root, emit}) {
    const refresh = function () {
      innerRefreshTrigger.value = true;
    };
    onMounted(() => {
      refresh(false);
    });

    watch(() => props.refreshTrigger, (newValue) => {
      if (newValue) {
        refresh(true);
        emit('update:refresh-trigger', false);
      }
    })

    // Data Table
    const headers = ref([
      {text: root.$t('album_request.created_at'), value: 'created_at'},
      {text: root.$t('album_request.album_title'), value: 'title', sortable: false},
      {text: root.$t('album_request.purpose'), value: 'purpose', sortable: false},
      {text: root.$t('album_request.admins'), value: 'admins', sortable: false},
      {text: root.$t('album_request.approved'), value: 'approved'},
      {text: root.$t('album_request.approved_by'), value: 'approved_by.name', sortable: false},
      {text: root.$t('album_request.approved_at'), value: 'approved_at'},
      // {text: '', value: 'actions', sortable: false},
    ]);
    const innerRefreshTrigger = ref(false);

    const getAdmins = function (admins) {
      return admins.map((a) => `${a.name} ${a.title ? '(' + a.title + ')' : ''} <${a.username}>`).join("<br>")
    };
    const getApproved = function (value) {
      if (value === true) {
        return root.$t('album_request.approved_true');
      } else if (value === false) {
        return root.$t('album_request.approved_false');
      } else {
        return root.$t('album_request.approved_pending');
      }
    }

    const deleteItem = async function (item) {
      if (await root.$dialog.confirm({text: root.$t('album_request.delete_confirm_msg')})) {
        const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'request/delete/',
          params: {request_id: item.id},
        })

        if (response.body.success) {
          // alert(root.$t('album_request.delete_success_msg'));
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, { content: root.$t('album_request.delete_success_msg'), isTop: true })
          await refresh();
        }
      }
    }

    return {
      headers,
      innerRefreshTrigger,
      getAdmins,
      getApproved,

      deleteItem,
    }
  }
}
</script>

<style lang="less">
.album-request-list {

}
</style>
