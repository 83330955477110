<template>
  <div>
    <viewer :options="viewerOptions" :images="photos" ref="photoViewer" @inited="onViewerInit">
      <template slot-scope="{images}">
        <img v-for="(src, idx) in images" :key="idx" :src="src.source" :alt="src.title" style="display: none;">
      </template>
    </viewer>
    <div class="photo-caption" v-show="photoIsShowing">{{showingPhotoTitle}}</div>
  </div>
 
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";
import { ACTION_TYPES } from "@/store/types";

export default {
  name: 'PhotoViewer',
  setup(props, { root }) {
    const photos = computed(() => {
      return root.$store.getters.displayingPhotos.map((p) => {
        return {
          thumbnail: p.thumbnail,
          source: '/api/file/' + p.original,
          title: p.caption || ' ',
        }
      });
    })

    let showingPhotoTitle = ref('')

    const photoIsShowing = computed(() => {
      return Boolean(root.$store.getters.displayingPhotos.length)
    })

    watch(photoIsShowing, (newValue) => {
      if(!newValue) {
        showingPhotoTitle.value = ''
      }
    })

    const viewer = ref(null);
    const onViewerInit = function (e) {
      viewer.value = e;
    };
    const viewerShown = ref(false);
    const viewerOptions = ref({
      toolbar: false,
      rotatable: false,
      navbar: false,
      transition: false,
      title: false,
      zIndex: 303,

      show(e) {
        viewerShown.value = true;
      },
      hide(e) {
        root.$store.dispatch(ACTION_TYPES.HIDE_PHOTOS);
      },
      viewed(e) {
        viewerShown.value = true;
        showingPhotoTitle.value = root.$store.getters.displayingPhotos[e.target.viewer.index].caption
      },
    });

    watch(() => photos.value, (newValue) => {
      if (newValue && newValue.length > 0) {
        setTimeout(() => {
          viewer.value.view(0);
        }, 50)
      }
    })

    return {
      photos,
      onViewerInit,
      viewerShown,
      viewerOptions,
      showingPhotoTitle,
      photoIsShowing,
    }
  },
}
</script>

<style lang="less">
  body {
    padding-right: 0px !important;
  }
  .photo-caption {
    position: fixed;
    bottom: 15px;
    left: 0;
    right: 0;
    z-index: 10000;
    color: rgb(208, 208, 208);
    text-align: center;
    font-size: 20px;
  }
</style>