import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCarousel,{staticClass:"album-item-viewer",attrs:{"hide-delimiters":"","max-width":"80vw","max-height":"80vh"},model:{value:(_vm.showingItemIdx),callback:function ($$v) {_vm.showingItemIdx=$$v},expression:"showingItemIdx"}},[_vm._l((_vm.items),function(item,i){return _c(VCarouselItem,[(item.exif && item.exif !== '{}')?_c('div',{staticClass:"album-item-exif-wrapper"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"album-item-exif-btn",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('pre',{staticClass:"album-item-exif-div",domProps:{"innerHTML":_vm._s(_vm.getExif(item.exif))}})])],1):_vm._e(),(item.type === 'IMAGE')?_c(VImg,{attrs:{"src":'/api/file/' + item.content,"contain":"","width":"100%","height":"100%"},on:{"load":function($event){return _vm.itemViewed(item)}}}):_vm._e(),(item.type === 'VIDEO')?_c('video',{attrs:{"controls":"","width":"100%","height":"100%","autoplay":!_vm.slideshowOn},on:{"play":function($event){return _vm.itemViewed(item)}}},[_c('source',{attrs:{"src":'/api/file/' + item.content}})]):_vm._e(),(item.caption)?_c('div',{staticClass:"album-item-caption"},[_vm._v(_vm._s(item.caption)+" ")]):_vm._e()],1)}),_c('div',{staticClass:"album-item-slideshow-div"},[_c('div',[_c(VBtn,{class:['album-item-slideshow-btn', {'enabled': _vm.slideshowOn}],attrs:{"icon":"","x-large":""},on:{"click":function($event){_vm.slideshowOn = !_vm.slideshowOn}}},[_c(VIcon,{attrs:{"color":_vm.slideshowOn? 'white' : 'grey'}},[_vm._v("mdi-update")])],1)],1),_c('div',{staticClass:"mt-2"},[_c(VBtn,{staticClass:"album-item-slideshow-btn",attrs:{"icon":""},on:{"click":_vm.decreaseSlideShowInterval}},[_c(VIcon,[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.slideShowInterval)+"s")]),_c(VBtn,{staticClass:"album-item-slideshow-btn",attrs:{"icon":""},on:{"click":_vm.increaseSlideShowInterval}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }