<template>
  <v-container class="album-request-view"
               v-if="account">
    <div class="album-breadcrumb">
      <v-btn text x-small color="primary" exact :to="{ name: 'album-request' }">
        &lt;&lt; {{ $t('album_request.title') }}
      </v-btn>
    </div>
    <h2 class="mb-4">{{ $t('album_request.create_title') }}</h2>
    <v-form ref="form">
      <v-text-field outlined
                    :label="$t('album_request.created_by')"
                    :value="account.name"
                    readonly></v-text-field>
      <v-text-field outlined
                    :label="$t('album_request.mobile')"
                    :value="account.mobile"
                    readonly></v-text-field>
      <v-text-field outlined
                    :label="$t('album_request.email')"
                    :value="account.email"
                    :hint="$t('album_request.email_hint')"
                    persistent-hint
                    readonly></v-text-field>
      <v-text-field outlined
                    :label="$t('album_request.album_title')"
                    v-model="title"
                    :rules="requiredRules"></v-text-field>
      <v-text-field outlined
                    :label="$t('album_request.purpose')"
                    v-model="purpose"
                    :rules="requiredRules"></v-text-field>
      <MultipleAccountInput v-model="adminIds"
                            :label="$t('album_request.admins')"
                            :hint="$t('album_request.admins_hint')"></MultipleAccountInput>


      <v-btn class="mt-4"
             block
             color="primary"
             @click="$refs.form.validate() && submit()">
        {{ $t('album_request.submit') }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import MultipleAccountInput from "@/components/MultipleAccountInput.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'AlbumRequestCreateView',
  components: {MultipleAccountInput},
  setup(props, {root}) {
    const requiredRules = ref([
      value => !!value || 'Required',
    ]);

    const account = computed(() => {
      return root.$store.getters.account;
    });

    const title = ref(null);
    const purpose = ref(null);
    const adminIds = ref([]);

    const submit = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'request/create/',
        params: {
          title: title.value,
          purpose: purpose.value,
          admin_ids: adminIds.value,
        },
      });

      if (response.body.success) {
        // alert(root.$t('album_request.submit_success_msg'));
        root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, { content: root.$t('album_request.submit_success_msg'), isTop: true })
        root.$router.push({name: 'album-request'})
      }
    }

    onMounted(() => {
      adminIds.value = [root.$store.getters.account.id];
    })

    return {
      account,
      title,
      purpose,
      adminIds,
      
      requiredRules,
      submit,
    }
  },
}
</script>

<style lang="less">
.album-request-view {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
