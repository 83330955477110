<template>
  <v-container fluid
               class="album-list-view pa-0"
               :class="['album']"
               v-if="totalNo !== null">
    <v-btn class="album-request-btn"
           color="primary"
           :to="{name: 'album-request'}">
           <span v-if="screen_w > 500">{{ $t('album_request.title')}}</span>
           <v-icon width="100%" v-else>
              mdi-image-plus
           </v-icon>
    </v-btn>
    <div class="album-list-title">{{ $t('album.title') }}</div>
    <div class="album-list-total-count mb-2">{{ totalNo }} Albums</div>
    <div class="album-list-search-field">
      <v-text-field solo
                    rounded
                    clearable
                    hide-details
                    prepend-inner-icon="fas fa-search"
                    :placeholder="$t('album.search')"
                    autocomplete="off"
                    v-model="searchString"></v-text-field>
    </div>
    <div class="album-list-divider my-4"></div>
    <div class="album-list-header">
      <div class="album-list-sort-by"></div>
      <div class="album-list-page-pagination">
        <v-pagination :length="totalPageNo"
                      v-model="pageNo"
                      total-visible="10"
                      @input="getAlbums"
        ></v-pagination>
      </div>
    </div>
    <div class="album-list-content">
      <v-col cols="12"
             sm="6"
             md="4"
             lg="3"
             class="album-cell"
             v-for="album in albums"
             :key="album.id">
        <div class="album-card"
             @click="onAlbumClick(album)">
          <!--Cover Photo-->
          <v-img v-if="album.cover_photo"
                 :src="album.cover_photo.thumbnail"
                 class="album-card-thumbnail"></v-img>
          <div v-else
               class="album-card-thumbnail">
            <v-icon x-large
                    dark>
              mdi-image-album
            </v-icon>
          </div>

          <!--Info-->
          <div class="album-card-info">
            <div class="album-card-title">
              <div class="album-title">{{ album.title }}</div>
              <span class="album-img-count">{{ `${album.media_count} Images` }}</span>
            </div>
            <!--            <div class="album-card-photo-count">-->
            <!--              {{ $t('album.photo_count', {count: album.photo_count}) }}-->
            <!--            </div>-->
            <div class="album-card-info-footer">
              <div class="album-card-view-container">
                <v-tooltip bottom>
                  <template #activator="{on, attrs}">
                    <div class="album-card-view-count" v-bind="attrs" v-on="on">
                      {{ album.view_no }}
                      <v-icon color="secondary" small>mdi-eye</v-icon>
                    </div>
                  </template>
                  <span>{{ $t('album.view_no', {view_no: album.view_no}) }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{on, attrs}">
                    <div class="album-card-date-count" v-bind="attrs" v-on="on">
                      {{ getCreateDays(album.created_at) }} days
                      <v-icon color="secondary" small>mdi-timer</v-icon>
                    </div>
                  </template>
                  <span>{{ $t('album.album_creation_days', {d: getCreateDays(album.created_at)}) }}</span>
                </v-tooltip>
                <v-tooltip v-if="album.visible_until"
                         bottom>
                  <template #activator="{on, attrs}">
                    <div class="album-card-visible-until"
                        v-bind="attrs"
                        v-on="on">
                      {{ $t('album.visible_until_day', {day: getVisibleDays(album.visible_until)}) }}
                      <v-icon color="secondary" small>mdi-timer</v-icon>
                    </div>
                  </template>
                  <span>{{ $t('album.visible_until', {d: $parseDate(album.visible_until)}) }}</span>
                </v-tooltip>
              </div>
              <v-icon color="primary" size="24">mdi-logout-variant</v-icon>
            </div>
          </div>
        </div>
      </v-col>
    </div>
  </v-container>
</template>

<script>
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import {DateTime} from "luxon";
import _ from "lodash";

export default {
  name: 'AlbumListView',
  setup(props, {root}) {
    const searchString = ref('');
    const debounced = _.debounce(() => {
      getAlbums();
    }, 500)
    watch(() => searchString.value, () => {
      debounced();
    })

    const pageNo = ref(1);
    const pageSize = ref(12);
    const sortBy = ref([]);
    const totalNo = ref(null);

    const totalPageNo = computed(() => {
      if (totalNo.value) {
        return Math.ceil(totalNo.value / pageSize.value);
      }
      return 1;
    })

    const albums = ref([]);
    const getAlbums = async function () {
      let params = {
        page_no: pageNo.value,
        page_size: pageSize.value,
        sort_by: ['-order_no'],
      };

      if (searchString.value && searchString.value.length > 0) {
        params.filters = {
          title: searchString.value,
        }
      }

      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'album/',
        params,
      });

      if (response.body.success) {
        albums.value = response.body.albums;
        totalNo.value = response.body.count;
      }
    };

    const getVisibleDays = function (d) {
      const visibleUntil = DateTime.fromISO(d);
      const now = DateTime.fromJSDate(new Date());
      return Math.ceil(visibleUntil.diff(now, ['days']).toObject()['days']);
    }

    const getCreateDays = function (d) {
      const createUntil = DateTime.fromISO(d);
      const now = DateTime.fromJSDate(new Date());
      return Math.abs(Math.ceil(createUntil.diff(now, ['days']).toObject()['days']))
    }

    const onAlbumClick = function (item) {
      root.$router.push({name: 'photo-list', params: {albumId: item.id}});
    }

    onMounted(() => {
      getAlbums();
    });

    return {
      searchString,
      pageNo,
      totalPageNo,
      pageSize,
      sortBy,
      totalNo,
      albums,
      getAlbums,
      getVisibleDays,
      getCreateDays,
      onAlbumClick,
      screen_w: screen.width
    }
  },
}
</script>

<style lang="less">
.album-list-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .album-request-btn {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .album-list-title {
    font-weight: b;
    font-size: 125%;
  }

  .album-list-total-count {
    font-size: 75%;
    color: #7D6C6C;
  }

  .album-list-search-field {
    width: 25vw;
    min-width: 300px;
    max-width: 500px;
  }

  .album-list-divider {
    width: 100%;
    border-bottom: solid 1px #ECECEE;
  }

  .album-list-header {
    width: 100%;
    display: flex;

    .album-list-sort-by {
      flex: 1 1 0;
    }

    .album-list-page-pagination {
      flex: 0 0 auto;
    }
  }

  .album-list-content {
    width: 100%;
    flex: 1 1 0;
    overflow: auto;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;

    .album-cell {
      // height: 150px;
      // padding: 4px;
      height: 365px;

      .album-card {
        border-radius: 8px;

        height: 100%;
        width: 100%;
        // background: #FFFFFF;
        background: transparent;
        // border: 1px solid rgba(63, 43, 143, 0.1);
        // padding: 16px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .album-card-thumbnail {
          // width: 100px;
          // height: 100px;
          border-radius: 8px;
          width: 100%;
          height: 250px;

          background-color: gray;

          flex: 0 0 auto;

          display: flex;
          justify-content: center;
        }

        .album-card-info {
          flex: 1 1 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          // padding-left: 8px;
          padding: 8px 2px;

          .album-card-title {
            line-height: 18px;
            flex: 1 1 0;
            overflow: hidden;
            word-break: break-all;

            .album-title {
              font-weight: bold;
              font-size: 17px;
            }

            .album-img-count {
              font-size: 13px;
              color: #8c7d7d;
            }
          }

          .album-card-photo-count, .album-card-export {
            flex: 0 0 auto;
            font-size: 75%;
            color: var(--v-secondary-base);
            user-select: none;
          }

          .album-card-info-footer {
            flex: 0 0 auto;
            display: flex;
            color: var(--v-secondary-base);
            font-size: 75%;
            justify-content: space-between;
            user-select: none;

            .album-card-view-container {
              display: flex;
              gap: 20px;

              .album-card-view-count, .album-card-date-count {
                font-size: 13px;
                display: inline-flex;
                align-items: center;
                gap: 3px;
              }
            }
            

            .album-card-visible-until {
              padding-left: 8px;
              cursor: zoom-in;
            }
          }
        }
      }
    }
  }

  .album-list-footer {
    width: 100%;

    display: flex;
    justify-content: flex-end;
  }
}
</style>
