export const ACTION_TYPES = {
    // loadingSpinner
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    CLEAR_LOADING: 'CLEAR_LOADING',
    SET_LOADING_MSG: 'SET_LOADING_MSG',

    // snackBar
    SHOW_SNACKBAR: 'SHOW_SNACKBAR',
    HIDE_SNACKBAR: 'HIDE_SNACKBAR',

    // API
    CALL_API: 'CALL_API',

    // Account
    AUTHENTICATE_TEAMNOTE_JWT: 'AUTHENTICATE_TEAMNOTE_JWT',
    SET_ME: 'SET_ME',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    GET_ME: 'GET_ME',

    // Photo Viewer
    SHOW_PHOTOS: 'SHOW_PHOTOS',
    HIDE_PHOTOS: 'HIDE_PHOTOS',
}
