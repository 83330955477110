import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"multiple-account-input"},[(!_vm.readonly)?_c(VAutocomplete,{attrs:{"outlined":"","hide-details":!_vm.hint,"label":_vm.label,"hint":_vm.hint,"persistent-hint":"","items":_vm.accounts,"multiple":"","return-object":"","search-input":_vm.searchInput,"filter":_vm.customFilter,"item-text":_vm.accountText,"menu-props":{closeOnContentClick: true}},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"change":_vm.onAccountSelect},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c(VListItemContent,{staticClass:"text-left"},[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(item.name)}}),(item.title)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e(),(item.mobile)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(item.mobile)}}):_vm._e(),(item.email)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(item.email)}}):_vm._e()],1)]}}],null,false,3893977126),model:{value:(_vm.dummySelected),callback:function ($$v) {_vm.dummySelected=$$v},expression:"dummySelected"}}):_vm._e(),_c(VRow,{staticClass:"multiple-account-input-list",attrs:{"no-gutters":""}},_vm._l((_vm.selectedAccounts),function(account,idx){return _c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VListItem,{staticClass:"multiple-account-input-invitation-item px-0"},[(!_vm.readonly)?_c(VListItemAvatar,[(_vm.showClear(account))?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.removeAccount(idx)}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1):_vm._e(),_c(VListItemContent,{staticClass:"text-left"},[_c(VListItemTitle,[_vm._v(" "+_vm._s(account.name)+" ")]),_vm._l((_vm.filteredFields(account)),function(field){return _c(VListItemSubtitle,[_vm._v(" "+_vm._s(field)+" ")])})],2)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }