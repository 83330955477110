import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","fullscreen":"","content-class":"login-dialog","transition":"fade-transition"},model:{value:(_vm.dialogShown),callback:function ($$v) {_vm.dialogShown=$$v},expression:"dialogShown"}},[_c(VContainer,{staticClass:"login"},[_c(VRow,[_c(VCol,[_c(VImg,{attrs:{"src":require("../assets/img.png"),"height":"10vh","max-height":"200px","contain":""}})],1)],1),_c(VRow,{staticClass:"mb-4"},[_c(VCol,{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")])],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{attrs:{"outlined":"","label":_vm.$t('login.username')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{attrs:{"outlined":"","label":_vm.$t('login.password'),"type":"password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"primary","dark":""},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.$t('login.title'))+" ")])],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"primary","dark":""},on:{"click":_vm.$loginTeamNote}},[_vm._v(" "+_vm._s(_vm.$t('login.login_with_teamnote'))+" ")])],1)],1)],1),_c(VSnackbar,{staticClass:"login-snackbar",attrs:{"content-class":"snackbar-content","timeout":3000,"absolute":"","outlined":"","color":"primary"},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_c(VIcon,{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t('login.login_failed_message'))+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }