import {ACTION_TYPES} from "@/store/types";

export const snackBarModule = {
    state: {
        content: '',
        top: false,
    },
    mutations: {
        setContent(state, { content, isTop = false }) {
            state.content = content;
            state.top = isTop;
        },
        clearContent(state) {
            state.content = '';
            state.top = false;
        },
    },
    actions: {
        [ACTION_TYPES.SHOW_SNACKBAR]: {
            root: true,
            handler({commit}, { content, isTop = false }) {
                commit('setContent', { content, isTop });
            },
        },
        [ACTION_TYPES.HIDE_SNACKBAR]: {
            root: true,
            handler({commit}, content) {
                commit('clearContent', content);
            },
        },
    },
    getters: {
        snackBarContent(state) {
            return state.content;
        },
        isSnackBarTop(state) {
            return state.top;
        },
    },
}