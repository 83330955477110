<template>
  <v-container class="album-request-list">
    <div class="album-breadcrumb">
      <v-btn text x-small color="primary" exact :to="{ name: 'album-list' }">
        &lt;&lt; {{ $t('album.title') }}
      </v-btn>
    </div>
    <v-row class="flex-grow-0 mt-4">
      <h3>{{ $t('album_request.title') }}</h3>
      <v-spacer></v-spacer>
      <v-btn color="primary"
             class="ml-4"
             small
             :to="{name: 'album-request-create'}">
        <v-icon>mdi-plus</v-icon>
        New
      </v-btn>
    </v-row>
    <v-row class="flex-grow-1">
      <v-col>
        <AlbumRequestList full-height
                          :refresh-trigger.sync="refreshTrigger"></AlbumRequestList>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AlbumRequestList from "@/components/AlbumRequestList.vue";
import {ref} from "@vue/composition-api";

export default {
  name: 'AlbumRequestListView',
  components: {AlbumRequestList},
  setup(props, {root}) {
    const refreshTrigger = ref(false);
    const refresh = function () {
      refreshTrigger.value = true;
    };

    return {
      refreshTrigger,
      refresh,
    }
  },
}
</script>


<style lang="less">
.album-request-list {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
