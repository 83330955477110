import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:['remote-paginated-list', 'pa-0', {'full-height': _vm.fullHeight}],attrs:{"fluid":""}},[_c(VDataTable,_vm._b({staticClass:"remote-paginated-list--table",attrs:{"loading":_vm.isLoading,"items":_vm.items,"server-items-length":_vm.itemCount,"search":_vm.search,"options":_vm.tableOptions,"footer-props":{itemsPerPageOptions: [10, 20, 50], showFirstLastPage: true},"fixed-header":_vm.fullHeight},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.onTableOptionUpdated]},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c(VSimpleCheckbox,{attrs:{"indeterminate":_vm.selectedItemIds.length > 0,"ripple":false},on:{"input":_vm.selectedAllToggled}})]},proxy:true},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var select = ref.select;
return [_c(VSimpleCheckbox,{key:item.id,attrs:{"value":_vm.selectedItemIds.indexOf(item.id) !== -1,"ripple":false},on:{"input":function($event){return _vm.itemSelected(item)}}})]}},_vm._l((_vm.tableTemplateKeys),function(key){return {key:key,fn:function(context){return [_vm._t(key,null,null,context)]}}})],null,true)},'v-data-table',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }