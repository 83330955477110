<template>
  <v-carousel v-model="showingItemIdx"
              class="album-item-viewer"
              hide-delimiters
              max-width="80vw"
              max-height="80vh">
    <v-carousel-item v-for="(item, i) in items">
      <div class="album-item-exif-wrapper"
           v-if="item.exif && item.exif !== '{}'">
        <v-menu offset-y>
          <template #activator="{on, attrs}">
            <v-btn icon
                   class="album-item-exif-btn"
                   v-bind="attrs"
                   v-on="on">
              <v-icon color="white">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <pre class="album-item-exif-div" v-html="getExif(item.exif)"></pre>
        </v-menu>
      </div>

      <v-img v-if="item.type === 'IMAGE'"
             :src="'/api/file/' + item.content"
             contain
             width="100%"
             height="100%"
             @load="itemViewed(item)">
      </v-img>
      <video v-if="item.type === 'VIDEO'"
             controls
             width="100%"
             height="100%"
             :autoplay="!slideshowOn"
             @play="itemViewed(item)">
        <source :src="'/api/file/' + item.content">
      </video>

      <div class="album-item-caption"
           v-if="item.caption">{{ item.caption }}
      </div>
    </v-carousel-item>

    <div class="album-item-slideshow-div">
      <div>
        <v-btn icon
               x-large
               :class="['album-item-slideshow-btn', {'enabled': slideshowOn}]"
               @click="slideshowOn = !slideshowOn">
          <v-icon :color="slideshowOn? 'white' : 'grey'">mdi-update</v-icon>
        </v-btn>
      </div>
      <div class="mt-2">
        <v-btn icon
               class="album-item-slideshow-btn"
               @click="decreaseSlideShowInterval">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <span class="px-4">{{ slideShowInterval }}s</span>
        <v-btn icon
               class="album-item-slideshow-btn"
               @click="increaseSlideShowInterval">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>


  </v-carousel>
</template>

<script>
import {onBeforeUnmount, onMounted, ref} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import _ from "lodash";
import {prettyPrintJson} from "pretty-print-json";

export default {
  name: 'AlbumItemViewer',
  props: {
    viewingItemId: String,
    albumId: String,
    parentId: String,
  },
  setup(props, {root, emit}) {
    const showingItemIdx = ref(0);
    const items = ref([]);

    const getItems = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'album/item/',
        params: {
          filters: {
            'album.id': props.albumId,
            'parent.id': props.parentId ? props.parentId : false,
            'type': ['IMAGE', 'VIDEO'],
          },
          fields : ['content', 'id', 'type'], // content: for image preview, id: for udpate view_no
          page_size: -1,
        },
      });

      if (response.body.success) {
        items.value = response.body.items;
        // items.value = response.body.items.map((i) => {
        //   let exif = null;
        //   try {
        //     let d = JSON.parse(i.exif);
        //     let values = [];
        //
        //     if (d['Image']) {
        //       Object.keys(d['Image']).forEach((k) => {
        //         values.push(`${k}: ${d['Image'][k]}`);
        //       })
        //     }
        //     if (d['EXIF']) {
        //       Object.keys(d['EXIF']).forEach((k) => {
        //         values.push(`${k}: ${d['EXIF'][k]}`);
        //       })
        //     }
        //
        //     exif = values.sort().join("<br>") || null;
        //   } catch (e) {
        //     ''
        //   }
        //
        //   return {
        //     ...i,
        //     exif: exif,
        //   }
        // });

        showingItemIdx.value = _.findIndex(items.value, {id: props.viewingItemId});
      }
    }

    const slideshowOn = ref(false);
    const slideShowInterval = ref(5);
    let slideShow = null;
    const startSlideShow = function () {
      if (slideShow) {
        clearInterval(slideShow);
        slideShow = null;
      }
      slideShow = setInterval(() => {
        if (slideshowOn.value) {
          showingItemIdx.value = (showingItemIdx.value + 1) % items.value.length;
        }
      }, slideShowInterval.value * 1000)
    }

    const decreaseSlideShowInterval = function () {
      slideShowInterval.value = Math.max(1, slideShowInterval.value - 1);
      startSlideShow();
    }
    const increaseSlideShowInterval = function () {
      slideShowInterval.value = Math.min(30, slideShowInterval.value + 1);
      startSlideShow();
    }

    const getExif = function (exif) {
      return prettyPrintJson.toHtml(JSON.parse(exif));
    }

    const itemViewed = async function (item) {
      console.log('itemViewed', item.id);
      root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'view/',
        params: {
          id: item.id,
        },
        opt: {not_blocking: true},
      });
    }

    onMounted(() => {
      getItems();

      startSlideShow();
    })

    onBeforeUnmount(() => {
      clearInterval(slideShow);
    })

    return {
      showingItemIdx,
      items,
      slideshowOn,
      slideShowInterval,
      decreaseSlideShowInterval,
      increaseSlideShowInterval,
      getExif,
      itemViewed,
    }
  },
}
</script>

<style lang="less">
.v-dialog {
  box-shadow: none !important;
}

.album-item-exif-div {
  overflow: auto;
  max-height: 50vh;
  padding: 24px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  max-width: 50vw;
}

.album-item-viewer {
  //background: white;

  .album-item-exif-wrapper {
    position: fixed;
    top: 12px;
    left: 12px;
    text-align: right;
    color: white;

    .album-item-exif-btn {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .album-item-slideshow-div {
    position: fixed;
    top: 12px;
    right: 12px;
    text-align: right;
    color: white;

    .album-item-slideshow-btn {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .album-item-caption {
    position: fixed;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
  }
}
</style>
