import {ACTION_TYPES} from "@/store/types";
import {v4 as uuid} from "uuid";
import i18n from '@/plugins/i18n';

export const apiModule = {
    state: {
        baseUrl: '/api/',
        requests: {},
        loadingMessage: null,
    },
    mutations: {
        startRequest(state, {uid, record}) {
            state.requests = {
                ...state.requests,
                [uid]: record,
            };
        },
        endRequest(state, uid) {
            let d = state.requests;
            delete d[uid];
            state.requests = {...d};
        },
        clearRequest(state) {
            state.requests = {};
        },
        setLoadingMessage(state, msg) {
            state.loadingMessage = msg;
        },
    },
    actions: {
        [ACTION_TYPES.START_LOADING]: {
            root: true,
            handler({commit}, {uid, record, timeout}) {
                commit('startRequest', {uid, record});

                if (timeout !== -1) {
                    setTimeout(() => {
                        commit('endRequest', uid);
                    }, timeout || 30000);
                }
            }
        },
        [ACTION_TYPES.STOP_LOADING]: {
            root: true,
            handler({commit}, uid) {
                commit('endRequest', uid);
            }
        },
        [ACTION_TYPES.CLEAR_LOADING]: {
            root: true,
            handler({commit}) {
                commit('clearRequest');
            }
        },
        [ACTION_TYPES.SET_LOADING_MSG]: {
            root: true,
            handler({commit}, msg) {
                commit('setLoadingMessage', msg);
            }
        },
        [ACTION_TYPES.CALL_API]: {
            root: true,
            async handler({state, commit, dispatch}, {url, params, opt}) {
                const {not_blocking, throw_error, extra} = {
                    not_blocking: false,
                    throw_error: false,
                    extra: null,
                    ...opt,
                };
                const uid = uuid();

                if (!not_blocking) {
                    dispatch(ACTION_TYPES.START_LOADING, {
                        uid, record: {url, params}, timeout: 5 * 60 * 1000, ...extra,
                    });
                }

                try {
                    const response = await this._vm.$http.post(state.baseUrl + url, params, {timeout: 5 * 60 * 1000, ...extra});
                    if (!not_blocking) {
                        dispatch(ACTION_TYPES.STOP_LOADING, uid);
                    }
                    return response;
                } catch (response) {
                    if (!not_blocking) {
                        dispatch(ACTION_TYPES.STOP_LOADING, uid);
                    }

                    if (throw_error) {
                        throw response;
                    } else if (response.status === 401 || response.status === 302) {
                        dispatch(ACTION_TYPES.LOGOUT);
                    } else if (typeof response.body === 'object') {
                        if ('msg' in response.body) {
                            let msg = response.body.msg;
                            if (i18n.te('error_messages.' + msg)) {
                                dispatch(ACTION_TYPES.SHOW_SNACKBAR, { content: i18n.t(`error_messages.${msg}`) })
                                return false;
                            } else {
                                dispatch(ACTION_TYPES.SHOW_SNACKBAR, { content: msg });
                                return false;
                            }
                        }
                        dispatch(ACTION_TYPES.SHOW_SNACKBAR, { content: i18n.t('error_messages.default') });
                        return false;
                    } else {
                        dispatch(ACTION_TYPES.SHOW_SNACKBAR, { content: i18n.t('error_messages.default') });
                        return false;
                    }
                }
            },
        },
    },
    getters: {
        requests(state) {
            return state.requests;
        },
        requestCount(state) {
            return Object.keys(state.requests).length;
        },
        loadingMessage(state) {
            return state.loadingMessage;
        },
    },
}
