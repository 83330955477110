import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RemotePaginatedList',_vm._b({staticClass:"album-request-list",attrs:{"headers":_vm.headers,"multi-sort":"","data-url":"request/","data-key":"requests","refresh-trigger":_vm.innerRefreshTrigger},on:{"update:refreshTrigger":function($event){_vm.innerRefreshTrigger=$event},"update:refresh-trigger":function($event){_vm.innerRefreshTrigger=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.$parseDate(value) : '')+" ")]}},{key:"item.purpose",fn:function(ref){
var value = ref.value;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.admins",fn:function(ref){
var value = ref.value;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getAdmins(value))}})])]}},{key:"item.approved",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getApproved(value))+" ")]}},{key:"item.approved_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.$parseDate(value) : '')+" ")]}}])},'RemotePaginatedList',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }